import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import digitalAvatar from "../../../../assets/images/digitalocean.png";
import DropDown from '../../gitlab-repos/DropDown';
import { toastMessage } from "../../../../actions/toast";
import compareDates from "../../../../util/dev-ops/compareDates";
import { getUsersByEmail } from "../../../../util/users/getUsersByEmails";
import Avatar from '../../../global/Avatar';
import AUTH_BASE_URL from "../../../../util/AUTH_BASE_URL";

const getDigitalOceanDeploymentInfo = async (appId, deploymentId, token) => {
    try {
        if (appId) {
            const response = await fetch(`https://api.digitalocean.com/v2/apps/${appId}/deployments/${deploymentId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Network response was not ok: ${JSON.stringify(errorData)}`);
            }
            const data = await response.json();
            return data;
        }

    } catch (error) {
        console.error('Error fetching deployment info:', error);
        return null;
    }
};

function ProgressBar({ progress, phase }) {
    const getBackgroundColor = (phase) => {
        switch (phase) {
            case 'BUILDING':
                return '#ecb529'; // Yellow for BUILDING phase
            case 'DEPLOYING':
                return '#4ea5df'; // Green for DEPLOYING phase
            case 'FAILED':
                return '#f54242'; // Red for FAILED phase
            default:
                return '#e0e0e0'; // Default gray color
        }
    };

    const backgroundColor = getBackgroundColor(phase);

    return (
        <div style={{ width: '75%', backgroundColor: '#e0e0e0', borderRadius: '4px', overflow: 'hidden' }}>
            <div
                style={{
                    width: `${progress}%`,
                    backgroundColor: backgroundColor,
                    height: '24px',
                    transition: 'width 0.3s ease'
                }}
            />
        </div>
    );
}

// Recursive function to remove `_id` from object
const removeIdRecursive = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(item => removeIdRecursive(item));
    } else if (obj !== null && typeof obj === 'object') {
        const newObj = {};
        for (const key in obj) {
            if (key !== '_id') {
                newObj[key] = removeIdRecursive(obj[key]);
            }
        }
        return newObj;
    }
    return obj;
};

function TableRow(props) {
    const { app, session } = props;
    const [user, setUser] = useState(null);
    const [deploymentInfo, setDeploymentInfo] = useState(null);
    const [commitUser, setCommitUser] = useState(null);

    const calculateProgress = (progress) => {
        const totalSteps = progress?.total_steps || 0;
        const completedSteps = progress?.success_steps || 0;
        return totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;
    };

    const progress = app?.in_progress_deployment ? calculateProgress(app.in_progress_deployment.progress) : 0;
    const phase = app?.in_progress_deployment?.phase;


    useEffect(() => {
        const fetchDeploymentInfo = async () => {
            if (app?.active_deployment && !app.hasOwnProperty('in_progress_deployment')) {
                const fetchedDeploymentInfo = await getDigitalOceanDeploymentInfo(app.id, app.active_deployment.id, session.digitalAccessToken);
                setDeploymentInfo(fetchedDeploymentInfo);

                if (fetchedDeploymentInfo?.deployment?.cause_details?.git_push?.commit_author) {
                    const commitAuthor = fetchedDeploymentInfo.deployment.cause_details.git_push.commit_author;

                    const emailMatch = commitAuthor.match(/<([^>]+)>/);
                    const email = emailMatch ? emailMatch[1] : 'No email found';

                    const userResponse = await getUsersByEmail(email);
                    const userData = await userResponse;
                    setCommitUser(userData);
                }
            }
        };

        fetchDeploymentInfo();
    }, [app?.active_deployment, session.digitalAccessToken]);

    function handleCloneRepo(text) {
        navigator.clipboard.writeText(text).then(function () {
            props.toastMessage(`${text} has been saved to your clipboard`);
        }, function (err) {
            props.toastMessage('Could not copy text: ', err);
        });
    }

    async function deleteAppFromDatabase(appId) {
        try {
            const deleteResponse = await fetch(`${AUTH_BASE_URL}apps/${appId}`, {
                method: 'DELETE',
            });

            const deleteData = await deleteResponse.json();

            if (deleteResponse.ok) {
                console.log('App deleted successfully:', deleteData);
            } else {
                console.error('Failed to delete app:', deleteData);
            }
        } catch (error) {
            console.error('Error deleting app:', error);
        }
    }

    async function handleGoOnline(app) {

        const { active_deployment, created_at, __v, ...restOfApp } = app;

        const cleanSpec = removeIdRecursive(restOfApp);

        try {
            props.toastMessage('Bringing the app online, please wait a few moments for the page to reload.');
            const response = await fetch(`https://api.digitalocean.com/v2/apps`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${session.digitalAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ spec: cleanSpec }),
            });

            const data = await response.json();

            if (response.ok) {
                props.toastMessage('App brought online successfully.');

                await deleteAppFromDatabase(app._id);
                props.fetchData(props.session.digitalAccessToken, false)
            } else {
                props.toastMessage('Failed to bring app online.');
            }
        } catch (error) {
            console.error('Error bringing app online:', error);
            props.toastMessage('Error bringing app online.');
        }
    }
    const handleClearAppCache = async () => {
        try {
            const response = await fetch(`https://api.digitalocean.com/v2/apps/${app.id}/deployments`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${session.digitalAccessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ force_build: true })
            });

            const data = await response.json();
            if (response.ok) {
                props.fetchData(props.session.digitalAccessToken, false)
                props.toastMessage('Cache cleared and app is rebuilding.');
            } else {
                console.error('Failed to clear cache and rebuild app:', data);
                props.toastMessage('Failed to clear cache and rebuild app.');
            }
        } catch (error) {
            console.error('Error clearing cache and rebuilding app:', error);
            props.toastMessage('Error clearing cache and rebuilding app.');
        }
    };

    async function handleTakeOffline(app) {
        console.log('120', app.spec);
        try {
            if (!app.spec) {
                console.error('app.spec or app.spec.app is undefined');
                props.toastMessage('Error: app.spec or app.spec.app is undefined.');
                return;
            }

            const { activeDeployment, ...appDataToSave } = app.spec;

            const modifiedAppDataToSave = {
                ...appDataToSave,
                active_deployment: {
                    ...appDataToSave.active_deployment,
                    phase: 'OFFLINE'
                }
            };


            const deleteResponse = await fetch(`https://api.digitalocean.com/v2/apps/${app.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${session.digitalAccessToken}`
                }
            });

            if (deleteResponse.ok) {

                const saveResponse = await fetch(`${AUTH_BASE_URL}apps`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(modifiedAppDataToSave),
                });

                const saveData = await saveResponse.json();

                if (saveResponse.ok && saveData.message === 'App saved successfully') {
                    props.toastMessage('App taken offline and removed from DigitalOcean successfully.');
                    props.fetchData(props.session.digitalAccessToken, false);
                } else {
                    console.error('Failed to save app:', saveData);
                    props.toastMessage('Failed to save app.');
                }
            } else {
                console.error('Failed to remove app from DigitalOcean');
                props.toastMessage('Failed to remove app from DigitalOcean.');
            }
        } catch (error) {
            console.error('Error taking app offline:', error);
            props.toastMessage('Error taking app offline.', error);
        }
    }


    const getActivityColor = (app) => {
        if (app.hasOwnProperty('in_progress_deployment')) {
            if (app.in_progress_deployment.phase === 'BUILDING') {
                return "badge-warning";
            } else {
                return "badge-deploying";
            }
        }

        const domainPhase = app?.domains?.[0]?.phase;
        if (domainPhase === 'CONFIGURING' && !app.hasOwnProperty('in_progress_deployment')) {
            return "badge-info";
        } else if (app.hasOwnProperty('pending_deployment')) {
            return "badge-info";

        } else if (app?.active_deployment?.phase === 'ACTIVE') {
            return "badge-success";
        } else if (app?.active_deployment?.phase === 'FAILED' || app?.active_deployment?.phase === 'DEPLOYMENT_FAILED') {
            return "badge-danger";
        } else if (app?.active_deployment?.phase === 'OFFLINE') {
            return "badge-offline"
        }

        return "badge-secondary";
    };

    const getPhaseText = (app) => {
        const domainPhase = app?.domains?.[0]?.phase;
        if (app.hasOwnProperty('in_progress_deployment')) {
            return 'Building and Deploying';
        }

        if (domainPhase === 'CONFIGURING' && !app.hasOwnProperty('in_progress_deployment')) {
            return 'Domain Configuring';
        } else if (app?.active_deployment?.phase === 'ACTIVE') {
            return 'Active';
        } else if (app?.active_deployment?.phase === 'FAILED' || app?.active_deployment?.phase === 'DEPLOYMENT_FAILED') {
            return 'Failed';
        } else if (app?.active_deployment?.phase === 'OFFLINE') {
            return 'Offline';
        }
        return 'Unknown';
    };

    const showSpinner = app?.hasOwnProperty('pending_deployment') || app.hasOwnProperty('in_progress_deployment') || app?.domains?.[0]?.phase === 'CONFIGURING' || getPhaseText(app) === 'Unknown';
    const colSpan = showSpinner ? 2 : 2;

    const getRepoUrls = (app) => {
        let urls = { https: '', ssh: '' };
        if (app?.spec?.services) {
            for (let service of app.spec.services) {
                if (service.gitlab && service.gitlab.repo) {
                    urls.https = `https://gitlab.com/${service.gitlab.repo}`;
                    urls.ssh = `git@gitlab.com:${service.gitlab.repo}.git`;
                    return urls;
                }
            }
        }
        if (app?.spec?.static_sites) {
            for (let site of app.spec.static_sites) {
                if (site.gitlab && site.gitlab.repo) {
                    urls.https = `https://gitlab.com/${site.gitlab.repo}`;
                    urls.ssh = `git@gitlab.com:${site.gitlab.repo}.git`;
                    return urls;
                }
            }
        }
        return urls;
    };

    function getElapsedTime(startedAt, endedAt) {
        if (!startedAt || !endedAt) return '';
        const start = new Date(startedAt);
        const end = new Date(endedAt);
        const diffMs = end - start;
        const diffSecs = Math.floor((diffMs / 1000) % 60);
        const diffMins = Math.floor((diffMs / (1000 * 60)) % 60);
        const diffHours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
        return `${diffHours}h ${diffMins}m ${diffSecs}s`;
    }

    const isProtectedApp = (appName) => {
        const protectedApps = ['endeavor-production', 'endeavor-development'];
        return protectedApps.includes(appName);
    };

    const handleDeleteAppspec = (appId) => {
        console.log('App ID:', appId._id);
        fetch(`${AUTH_BASE_URL}apps/${appId._id}`, {
            method: 'DELETE'
        })
            .then(response => response.json())
            .then(data => {
                console.log('App deleted successfully:', data);
                props.toastMessage('App deleted successfully.');
                props.fetchData(props.session.digitalAccessToken, false);
            })
            .catch(error => {
                console.error('Error deleting app:', error);
                props.toastMessage('Error deleting app.');
            })
    };

    const { https: repoHttpsUrl, ssh: repoSshUrl } = getRepoUrls(app);
    return (
        <>
            <style>
                {`
                .badge-success {
                    background-color: green;
                }
                .badge-danger {
                    background-color: red;
                }
                .badge-warning {
                    background-color: orange;
                }
                .circle.badge-deploying {
                    background-color: #4ea5df; 
                    display: block;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                }
                         .circle.badge-info {
                    background-color: #198eb1; 
                    display: block;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                }
                .badge-secondary {
                    background-color: grey;
                }
                .badge-offline {
                    background-color: #333; 
                    display: block !important;
                    height: 25px !important;
                    width: 25px !important;
                    border-radius: 50% !important;
                }
                .circle {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                }
                `}
            </style>
            <tr>
                <td>
                    {showSpinner ? (
                        <div className="spinner-border text-primary border-blue" role="status"
                            style={{ display: 'flex', border: '.30em solid #0080FF', borderRightColor: 'transparent' }}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        commitUser ? <Avatar user={commitUser} size='md' /> :
                            <img style={{ width: '30px' }} alt="avatar" src={digitalAvatar} />
                    )}
                </td>
                <td>
                    {app?.live_url || app.hasOwnProperty('in_progress_deployment') ? (
                        <a href={`https://cloud.digitalocean.com/apps/${app?.id}`} target="_blank"
                            rel="noopener noreferrer">
                            {app?.spec?.name || 'N/A'}
                        </a>
                    ) : (
                        <span>{app?.name || 'N/A'}</span>
                    )}
                </td>
                <td>
                    {app?.hasOwnProperty('pending_deployment') ? (
                        <span>Pending Deployment</span>
                    ) : app?.hasOwnProperty('in_progress_deployment') ? (
                        <div>
                            <ProgressBar progress={progress} phase={phase} />
                        </div>
                    ) : app?.live_url || !app?.domains?.[0]?.phase === 'CONFIGURING' ? (
                        <a href={app?.live_url} target="_blank" rel="noopener noreferrer">
                            {app?.live_url}
                        </a>
                    ) : (
                        <span>Offline</span>
                    )}
                </td>

                <td>{app?.created_at ? new Date(app.last_deployment_active_at).toLocaleDateString() : 'N/A'}</td>
                <td>
                    <span data-placement="top" data-toggle="tooltip" title="Currently Worked On"
                        className={`circle badge ${getActivityColor(app)}`}>
                    </span>
                </td>
                <td>
                    <DropDown data={app} label={"Manage"} icon={"fa-chevron-down"}>
                        {app?.live_url && app?.active_deployment?.phase !== 'OFFLINE' && (
                            <li>
                                <button onClick={() => window.open(app.live_url, '_blank')}>View App</button>
                            </li>
                        )}
                        {app?.live_url || app.hasOwnProperty('in_progress_deployment') ? (
                            <li>
                                <button onClick={() => window.open(`https://cloud.digitalocean.com/apps/${app.id}`, '_blank')}>
                                    View on Digital
                                </button>
                            </li>
                        ) : null}
                        {repoHttpsUrl && (
                            <li>
                                <button onClick={() => window.open(repoHttpsUrl, '_blank')}>View on GitLab</button>
                            </li>
                        )}
                        {app?.live_url && repoSshUrl && (
                            <li>
                                <button onClick={() => handleCloneRepo(repoSshUrl)}>Clone with SSH</button>
                            </li>
                        )}
                        {app?.live_url && (
                            <li>
                                <button onClick={() => handleClearAppCache()}>Clear App Cache</button>
                            </li>
                        )}

                        {
                            !isProtectedApp(app?.spec?.name) && (
                                <>
                                    {app?.live_url ? (
                                        <li>
                                            <button onClick={() => handleTakeOffline(app)}>Take offline</button>
                                        </li>
                                    ) : (
                                        <li>
                                            <button onClick={() => handleGoOnline(app)}>Go Online</button>
                                        </li>
                                    )}
                                    {app?.active_deployment?.phase === 'OFFLINE' && (
                                        <li>
                                            <button onClick={() => handleDeleteAppspec(app)}>Delete Appspec</button>
                                        </li>
                                    )}
                                </>
                            )
                        }
                    </DropDown>
                </td>
            </tr>
        </>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(TableRow);
