import React, { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toastMessage } from "../../../../../actions/toast";
import { handleCreateRepo } from '../../../../../util/dev-ops/createReactRepo';
import { signin } from "../../../../../actions/session/signIn";
import SpinnerButton from "../../../../global/SpinnerButton"; // Ensure you have the SpinnerButton component

const CardComponent = (props) => {
    const [subdomain, setSubdomain] = useState('');
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();
    const digitalOceanToken = props.session.digitalAccessToken; // Use the token from props

    const handleInputChange = (e) => {
        setSubdomain(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            await handleCreateRepo(
                subdomain,
                props.session,
                props.signin,
                props.toastMessage,
                digitalOceanToken,
                navigate,
                `https://gitlab.com/pearson-ct/react-vite-template.git`
            );
        } catch (error) {
            props.toastMessage(error.message);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleCardClick = () => {
        setShowForm(true);
    };

    const cardContainerStyle = {
        perspective: '1000px',
        width: '100%', // Adjust width as needed
        margin: '20px auto',
        minWidth:'390px'

    };

    const cardStyle = {
        width: '100%',
        height: '300px', // Set a fixed height
        transformStyle: 'preserve-3d',
        transition: 'transform 0.6s',
        position: 'relative',
        transform: 'none'
    };

    const cardSideStyle = {
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
        position: 'absolute',
        top: '0',
        left: '0',
        padding: '20px',
        border: '1px solid #ced4da',
        borderRadius: '0.25rem',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around', // Center content vertically
        alignItems: 'center', // Center content horizontally
        textAlign: 'center', // Center text
    };

    const iconContainerStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center',
        gap: '20px', // Adjust the gap between icons as needed
        marginTop: '20px', // Add margin to separate icons from text
        width: '100%'
    };

    return (
        <div style={cardContainerStyle} >
            <div style={cardStyle}>
                <div style={cardSideStyle}>
                    <div>
                        <h5 className="main-content-label"
                            style={{color: '#343a40', fontWeight: '500', marginBottom: '10px', fontSize: '18px'}}>Create React app.</h5>
                        <p className="card-subtitle" style={{color: '#6c757d', fontSize: '16px'}}>Use this to set up a
                            new Create React App repository and connect it to a domain of your choice. </p>
                    </div>
                    <div style={iconContainerStyle}>
                        <form onSubmit={handleSubmit} style={{width: '100%'}}>
                            <div className="form-group" style={{marginBottom: '20px'}}>

                                <div className="input-group" style={{display: 'flex', alignItems: 'center'}}>
                                    <input
                                        type="text"
                                        className="form-control mg-r-10"
                                        id="subdomain"
                                        value={subdomain}
                                        onChange={handleInputChange}
                                        placeholder="Enter subdomain"
                                        style={{
                                            flex: '1',
                                            padding: '10px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem 0 0 0.25rem'
                                        }}
                                        aria-label="Subdomain"
                                        aria-describedby="subdomain-addon"
                                    />
                                    <div className="input-group-append" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9ecef',
                                        borderRadius: '0 0.25rem 0.25rem 0',
                                        padding: '10px'
                                    }}>
                                            <span className="input-group-text"
                                                  id="subdomain-addon">.pearsonct.design</span>
                                    </div>
                                </div>
                            </div>
                            <SpinnerButton loading={loading} buttonText={'Create App'}/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user => dispatch(signin(user)),
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardComponent);
