import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import digitalAvatar from "../../../../assets/images/digitalocean.png";
import DropDown from '../../gitlab-repos/DropDown';
import { toastMessage } from "../../../../actions/toast";

function TableRow(props) {
    const { droplet } = props;

    const setActivityColor = (lastActivityAt) => {
        return Number(new Date(lastActivityAt));
    }

    function avatarSwitcher() {
        return <img style={{ width: '30px' }} alt="avatar" src={digitalAvatar} />;
    }
    const handlePowerCycle = async (droplet) => {
        console.log("DROPLET:", droplet)
        if (droplet?.id) {
            console.log("Droplet", droplet)
            const dropletId = droplet.id

            const API_TOKEN = process.env.REACT_APP_DIGITALOCEAN_DROPLET_TOKEN
            const BASE_URL = "https://api.digitalocean.com/v2"

            try {
                const response = await fetch(`${BASE_URL}/droplets/${dropletId}/actions`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${API_TOKEN}`,
                  },
                  body: JSON.stringify({ type: "power_cycle" }),
                });
                if (response.ok) {
                    const data =  {
                        message: "Droplet PowerCycle Successful",
                        type: "success"
                    }
                    console.log("PowerCycle Successful")
                    props.toastMessage(data.message)
                }
                else if (!response.ok) {
                    const data =  {
                        message: "Droplet PowerCycle Failed",
                        type: "error"
                    }
                    console.log("PowerCycle Failure")
                    props.toastMessage(data.message + response.statusText)
                }

                const data = await response.json();
                console.log("Droplet restart initiated:", data.action);
              } catch (error) {
                console.error("Failed to restart droplet:", error);
              }
        }
    }


    return (
        <tr>
            <td>{avatarSwitcher()}</td>
            <td>{droplet?.name || 'N/A'}</td>
            <td>{droplet?.memory ? `${droplet.memory} MB` : 'N/A'}</td>
            <td>{droplet?.vcpus || 'N/A'}</td>
            <td>{droplet?.disk ? `${droplet.disk} GB` : 'N/A'}</td>
            <td>{droplet?.status || 'N/A'}</td>
            <td>{droplet?.region?.name || 'N/A'}</td>
            <td>{droplet?.size?.price_monthly ? `$${droplet.size.price_monthly}/mo` : 'N/A'}</td>
            <td>{droplet?.created_at ? new Date(droplet.created_at).toLocaleDateString() : 'N/A'}</td>
            <td>
                <DropDown data={droplet} label={"Manage"} icon={"fa-chevron-down"} type="droplet">
                    {
                        droplet?.name && (
                            <>
                                <li>
                                    <button onClick={() => handlePowerCycle(droplet)}>Power Cycle</button>
                                </li>
                            </>
                        )
                    }
                </DropDown>
            </td>
        </tr>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => {
        console.log("DATA:", data)
        return dispatch(toastMessage(data))
    }
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(TableRow);
