import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef} from "react";
import getCiCd from "../../../util/dev-ops/getCiCd";
import validateToken from "../../../util/dev-ops/validateToken";
import {connect} from "react-redux";
import {checkForCiCd} from "../../../actions/projectHasCiCd";
import {signin} from "../../../actions/session/signIn";
import checkForAuthentication from "../../../util/dev-ops/checkForAuthentication";
import {hasAuthentication} from "../../../actions/hasAuthentication";
import {allTestData} from "../../../actions/allUserTests";

function DropDown (props) {
    const actionsRef = useRef(null)
	const hasAuthentication = async () => checkForAuthentication(props.data.name);
    const handleActionsMenu = async (event) => {
        if(actionsRef.current.classList.contains('show')){
            actionsRef.current.classList.remove('show')
            props.checkForCiCd(null)
        }  else{
            actionsRef.current.classList.add('show')

            validateToken(props.session).then(data => {
				props.signin(data.user)
                getCiCd(props.data, data.token).then(response => {

                    if (response && response.message) {
                        props.checkForCiCd(false)
                    } else {
                        props.checkForCiCd(props.data.id)
                    }
                })
            })
			checkForAuthentication(props.data.name).then(result => {
				props.getAuthentication(result)
			})
        }

    }
    return (
        <div className={'dropdown-container'} style={{position: 'relative'}}>
            <button type="button" className={props.label === 'Manage' ? 'btn btn-secondary my-2 btn-icon-text mg-0' : 'btn btn-secondary my-2 btn-icon-text mg-0'} onClick={handleActionsMenu} style={{margin: '0'}}>
				{props.label}
                <FontAwesomeIcon className="mg-l-4" icon={`fa-solid ${props.icon}`} />
            </button>
            <div ref={actionsRef} className={props.type? "menu-reposition dropdown-menu animated-menu actions-menu" : "dropdown-menu animated-menu actions-menu"} style={{minWidth: '12rem'}}>
                <ul style={{textAlign: 'left'}} onClick={()=>{
                    if(actionsRef.current.classList.contains('show')){
                        actionsRef.current.classList.remove('show')
                    }
                }}>
                    {props.children}
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = ({session, hasCiCd}) => ({
    session, hasCiCd
});

const mapDispatchToProps = dispatch => ({
	signin: user =>dispatch(signin(user)),
    checkForCiCd: bool => dispatch(checkForCiCd(bool)),
	getAuthentication: bool => dispatch(hasAuthentication(bool)),
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DropDown);
